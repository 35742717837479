<template lang="pug">
  div.wrap-module-home.pb70
    div.sub-header.f.fc
      div.sub-header-content.f.fm
        div.f.fm.mr12
          select(v-model="currentFilter").fz16.mr2
            option(v-for="label in filterLabels") {{label}}
          v-icon(size="18px") mdi-menu-down
        div.f.fm
          select(v-model="currentSort").fz16.mr2
            option(v-for="label in sortLabels") {{label}}
          v-icon(size="18px") mdi-menu-down
        //- div.f.fm.cursor-pointer.mr10
          //- v-icon(size="18px").mr4 mdi-filter-variant
          span.fz12.pt2.mr2 全て
          v-icon(size="16px") mdi-menu-down
        //- div.f.fm.cursor-pointer
          //- v-icon(size="18px").mr4 mdi-sort-variant
          span.fz12.pt2.mr2 新しい順
          v-icon(size="16px") mdi-menu-down
    div.home-content
      ItemMeetingCard(v-for="meeting in resultMeetings"
        :key="meeting.id"
        :meeting="meeting")
    div(@click="onCreateEvent").create-event.f.fh
      div.create-event-content.py12
        span.block.text-center.mr4 ミーティングを作成する

</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-home {
  width: 100%;
  min-height: 100vh;
  height: -webkit-fill-available;
  padding-top: $header_height;
  position: relative;
  .sub-header {
    position: fixed;
    width: 100%;
    top: $header_height;
    height: $sub_header_height;
    border-bottom: solid $border_size $border_color;
    background: $primary_bg_color;
    z-index: 1000;
    .sub-header-content {
      width: $base_width_percent;
      max-width: $base_max_width_px;
      select {
        outline: none;
      }
    }
  }
  .home-content {
    padding-top: $sub_header_height;
  }
  .create-event {
    z-index: 1;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 12px;
    .create-event-content {
      width: $base_width_percent;
      max-width: $base_max_width_px;
      cursor: pointer;
      background: $active_color;
      border-radius: 28px;
      filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
      span {
        color: #fff;
      }
    }
  }
}
</style>

<script>
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import ItemMeetingCard from '@/components/item/ItemMeetingCard'
export default {
  components: {
    ItemMeetingCard
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  data() {
    return {
      user: null,
      meetings: [],
      resultMeetings: [],
      currentFilter: '調整中',
      currentSort: '新しい順',
      filterLabels: [
        '全て',
        '調整中',
        '調整完了',
        '調整失敗' //, '招待', '非招待'
      ],
      sortLabels: ['新しい順', '古い順']
    }
  },
  watch: {
    currentFilter() {
      this.filterMeetings()
    },
    async currentSort() {
      switch (this.currentSort) {
        case '新しい順':
          this.meetings = await database.meetingCollection().getJoiningMeetings(this.user.email)
          break
        case '古い順':
          this.meetings = await database.meetingCollection().getJoiningMeetingsAsc(this.user.email)
          break
      }
      this.filterMeetings()
    }
  },
  async created() {
    this.user = await database.userCollection().findById(this.uid)
    this.meetings = await database.meetingCollection().getJoiningMeetings(this.user.email)

    switch (this.$route.params.filter) {
      case 'all':
        this.currentFilter = '全て'
        break
      case 'adjusting':
        this.currentFilter = '調整中'
        break
      case 'adjusted':
        this.currentFilter = '調整完了'
        break
      case 'failed':
        this.currentFilter = '調整失敗'
        break
      default:
        this.currentFilter = '調整中'
        break
    }

    this.filterMeetings()
  },
  methods: {
    filterMeetings () {
      switch (this.currentFilter) {
        case '全て':
          this.resultMeetings = this.meetings
          break
        case '調整中':
          this.resultMeetings = this.meetings.filter((e) => e.status === 'adjusting')
          break
        case '調整完了':
          this.resultMeetings = this.meetings.filter((e) => e.status === 'adjusted')
          break
        case '調整失敗':
          this.resultMeetings = this.meetings.filter((e) => e.status === 'failed')
          break
      }
    },
    onCreateEvent () {
      this.$root.$emit('sendTrackingEventByClick', { 
        message: 'click_create_meeting_btn', option: {}
      })
      this.$router.push('/create-meeting')
    }
  }
}
</script>
