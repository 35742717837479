<template lang="pug">
  div(@click="$router.push(backPath)"
    :style="`border-left: solid 6px ${statusColor};`").wrap-item-meeting-card.py8
    div.meeting-card-content
      div.wrap-description.f.fm.flex-between.mb6
        div.f.fm
          div.wrap-icon.mr6
            v-avatar(v-if="inviter" size="28px")
              v-img(:src="inviter.iconURL")
          div.wrap-title.mr2
            span.line-clamp-1.bold {{meeting.title}}
        v-menu(offset-y)
          template(v-slot:activator="{ on }")
            v-icon(v-on="on" size="20px") mdi-dots-vertical
          template
            div.meeting-card-more-window.px8.py4
              span(@click="$router.push(`/create-meeting/${meeting.id}`)").block.fz14.cursor-pointer 条件をコピーして新規作成
      div(v-if="meeting.status === 'adjusted'").wrap-adjusted-time.f.fm.pt3.mb2
        v-icon(size="17px").mr6 mdi-clock-time-five
        span.fz12 {{adjustedTextStr}}
      div.wrap-member.f.fm.mb2
        div.f.fm.mr4
          v-icon(size="18px").mr6 mdi-account-multiple
          span.line-clamp-1.fz12 {{meeting.attendee.map(e => e.name).join(', ')}}
        div.f.fm
          div(v-if="meeting.createdBy != uid && inviter").wrap-inviter-info.f
            v-icon(size="16px").mr6 mdi-account-plus
            span.white-space-nowrap.fz12 Invited by {{inviter.name}}
          div(v-if="meeting.createdBy === uid").wrap-inviter-info.f
            v-icon(size="16px").mr6 mdi-account-plus
            span.white-space-nowrap.fz12 Invited by you
      //- div.wrap-inviter-info
        v-icon(size="18px").mr6 mdi-account-plus
        span.fz12 Invited by {{inviter.name}}
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-item-meeting-card {
  position: relative;
  border-bottom: solid $border_size $border_color;
  .meeting-card-content {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .wrap-title {
      max-width: 240px;
    }
    // .about {
    //   color: $secondary_text_color;
    // }
    .wrap-member,
    .wrap-adjusted-time,
    .wrap-inviter-info {
      color: $secondary_text_color;
    }
  }
}

.meeting-card-more-window {
  background: $primary_bg_color;
  span {
    color: $active_color;
    white-space: nowrap;
  }
}
</style>

<script>
import database from '@/database'
import format from 'date-fns/format'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  props: {
    meeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      statusText: '',
      statusColor: '',
      inviter: null,
      adjustedTextStr: ''
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    backPath() {
      // return this.$route.params.filter ?
      //   `/inviter-meeting/${this.meeting.id}/${this.$route.params.filter}`
      //   : `/inviter-meeting/${this.meeting.id}`
      if (this.$parent.currentFilter !== '全て') {
        return `/inviter-meeting/${this.meeting.id}/${this.meeting.status}`
      } else {
        return `/inviter-meeting/${this.meeting.id}/all`
      }
    }
  },
  async created() {
    if (this.meeting.status === 'adjusting') {
      this.statusText = '調整中'
      this.statusColor = '#1DC1AC'
    } else if (this.meeting.status === 'adjusted') {
      this.statusText = '調整完了'
      this.statusColor = '#4285f4'
      this.adjustedTextStr = `${format(
        this.meeting.adjustedDate.start.toDate(),
        'yyyy/MM/dd HH:mm'
      )} ~ ${format(this.meeting.adjustedDate.end.toDate(), 'HH:mm')}`
    } else if (this.meeting.status === 'failed') {
      this.statusText = '調整失敗'
      this.statusColor = '#ff5252'
    }

    this.inviter = await database.userCollection().findById(this.meeting.createdBy)
  }
}
</script>
