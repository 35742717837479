<template lang="pug">
  Auth(@loggedIn="onLoggedIn"
    :redirectWithLoginFailed="true")
    Header(:content="headerContent")
    DrawerMenu(v-if="uid"
      :uid="uid" :show="showMenu" @close="toggleMenu")
    div.wrap-home.f.fh
      ModuleHome(v-if="uid")

</template>

<style lang="scss" scoped>
.wrap-home {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import Header from '@/components/shared/Header'
import DrawerMenu from '@/components/shared/DrawerMenu'
import ModuleHome from '@/components/module/ModuleHome'

export default {
  components: {
    Auth,
    Header,
    DrawerMenu,
    ModuleHome
  },
  data() {
    return {
      headerContent: {
        title: 'ミーティング',
        left: {
          icon: 'mdi-menu',
          action: this.toggleMenu
        }
      },
      showMenu: false
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  methods: {
    onLoggedIn() {},
    toggleMenu() {
      this.showMenu = !this.showMenu
    }
  }
}
</script>
